export default class CustomerContactEntity {
    static getEntityData(elem: any) {
        return {
            uuid: elem.querySelector("#customer_contact_uuid") ? (elem.querySelector("#customer_contact_uuid") as HTMLInputElement).value : null,
            name: (elem.querySelector("#customer_contact_name") as HTMLInputElement).value,
            description: (elem.querySelector("#customer_contact_description") as HTMLInputElement).value,
            salutation: (elem.querySelector("#customer_contact_salutation") as HTMLInputElement).value,
            email: (elem.querySelector("#customer_contact_email") as HTMLInputElement).value,
            phone: (elem.querySelector("#customer_contact_phone") as HTMLInputElement).value,
            mobile: (elem.querySelector("#customer_contact_mobile") as HTMLInputElement).value,
        }
    }
}